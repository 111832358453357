







































































































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
    MTestEmail: () => import("@/components/molecules/m-test-email.vue"),
  },

  setup(_, { root }) {
    const { shortLanguageItems } = useSelectItems({ root });

    const model = reactive<{
      languageSwitch: string;
      polishTemplate: any;
      polishTitle: any;
      polishEditor: any;
      englishTemplate: any;
      englishTitle: any;
      englishEditor: any;
    }>({
      languageSwitch: "pl",
      polishTemplate: "",
      polishTitle: "",
      polishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      englishTemplate: "",
      englishTitle: "",
      englishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
    });

    const state = reactive({
      templates: [],
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      polishEditorLoaded: false,
      englishEditorLoaded: false,
      polishTemplateAdded: false,
      englishTemplateAdded: false,
      eventName: "",
      dialog: false,
      event: {},
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchTemplates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("message-template", { params: { type: "other" } })
        .then(({ data: { messageTemplates } }) => {
          state.templates = messageTemplates;
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            state.templates = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchTemplates);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (model.languageSwitch === "pl") {
        axiosInstance
          .get(`message-template/${model.polishTemplate}`)
          .then(({ data: { messageTemplate } }) => {
            model.polishEditor.design = messageTemplate?.design || undefined;
            model.polishTitle = messageTemplate?.title || undefined;
            state.polishEditorLoaded = true;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            state.loading = false;
          });
      } else if (model.languageSwitch === "en") {
        axiosInstance
          .get(`message-template/${model.englishTemplate}`)
          .then(({ data: { messageTemplate } }) => {
            model.englishEditor.design = messageTemplate?.design || undefined;
            model.englishTitle = messageTemplate?.title || undefined;
            state.englishEditorLoaded = true;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            state.loading = false;
          });
      }
    };

    watch(() => model.polishTemplate, fetchData);
    watch(
      () => model.polishTemplate,
      () => {
        if (state.polishEditorLoaded) {
          state.polishEditorLoaded = false;
          fetchData();
        }
      }
    );
    watch(() => model.englishTemplate, fetchData);
    watch(
      () => model.englishTemplate,
      () => {
        if (state.englishEditorLoaded) {
          state.englishEditorLoaded = false;
          fetchData();
        }
      }
    );

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (model.languageSwitch === "pl") {
        axiosInstance
          .get(`event/${root.$route.params.id}`)
          .then(({ data: { event } }) => {
            state.event = event;
            model.polishEditor.design =
              event.additionalFeeForParticipantEditMail?.design || undefined;
            model.polishTitle =
              event.additionalFeeForParticipantEditMail?.title || undefined;
            state.polishEditorLoaded = event.additionalFeeForParticipantEditMail
              ?.design
              ? true
              : false;
            state.polishTemplateAdded = event
              .additionalFeeForParticipantEditMail?.design
              ? true
              : false;
            state.eventName = event.name;
            state.englishEditorLoaded = false;
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              state.templates = [];
            } else {
              console.log(error);
            }
            console.log(error);
          })
          .finally(() => {
            state.loaded = true;
            state.loading = false;
          });
      } else if (model.languageSwitch === "en") {
        axiosInstance
          .get(`event/${root.$route.params.id}`)
          .then(({ data: { event } }) => {
            state.event = event;
            model.englishEditor.design =
              event.englishAdditionalFeeForParticipantEditMail?.design ||
              undefined;
            model.englishTitle =
              event.englishAdditionalFeeForParticipantEditMail?.title ||
              undefined;
            state.englishEditorLoaded = event
              .englishAdditionalFeeForParticipantEditMail?.design
              ? true
              : false;
            state.englishTemplateAdded = event
              .englishAdditionalFeeForParticipantEditMail?.design
              ? true
              : false;
            state.eventName = event.name;
            state.polishEditorLoaded = false;
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              state.templates = [];
            } else {
              console.log(error);
            }
          })
          .finally(() => {
            state.loaded = true;
            state.loading = false;
          });
      }
    };

    onMounted(fetchEvent);

    watch(() => model.languageSwitch, fetchEvent);

    const onSubmit = (type: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        template:
          model.languageSwitch === "pl"
            ? model.polishEditor.html || undefined
            : model.englishEditor.html || undefined,
        design:
          model.languageSwitch === "pl"
            ? model.polishEditor?.design || undefined
            : model.englishEditor?.design || undefined,
        title:
          model.languageSwitch === "pl"
            ? model.polishTitle || undefined
            : model.englishTitle || undefined,
        type: type,
      };

      state.loading = true;
      if (
        (model.languageSwitch === "pl" && state.polishTemplateAdded) ||
        (model.languageSwitch === "en" && state.englishTemplateAdded)
      ) {
        axiosInstance
          .put(
            `/event/${root.$route.params.id}/message-additional-fee-from-participant-edit`,
            data,
            {
              headers: {
                event: root.$route.params.id,
              },
            }
          )
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("panel.event.abstract.config.mails.update"),
            });
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        axiosInstance
          .post(
            `/event/${root.$route.params.id}/message-additional-fee-from-participant-edit`,
            data,
            {
              params: { type: type },
              headers: {
                event: root.$route.params.id,
              },
            }
          )
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("panel.event.abstract.config.mails.save"),
            });
            fetchEvent();
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    watch(
      () => model.polishTitle,
      () => {
        if (model.polishTitle?.includes("{{ event.name }}")) {
          model.polishTitle = model.polishTitle.replace(
            "{{ event.name }}",
            state.eventName
          );
        }
      }
    );
    watch(
      () => model.englishTitle,
      () => {
        if (model.englishTitle?.includes("{{ event.name }}")) {
          model.englishTitle = model.englishTitle.replace(
            "{{ event.name }}",
            state.eventName
          );
        }
      }
    );

    return { model, state, onSubmit, getErrorMessage, shortLanguageItems };
  },
});
